//Dependencies
import {
  useMemo,
  useEffect
} from 'react';
import isEqual from 'lodash.isequal';

//Chakra
import {
  Text,
  Flex,
} from '@chakra-ui/react';

//Components
import {
  // ChartTags,
} from '../components';

//Providers
import { useWaveFinder } from '../providers/wave-finder';
import { useChart } from '../providers/chart';

//Helpers
import { toTitleCase } from '../helpers/utils'; 


export default function ChartFooter() {
  const {
    market,
  } = useChart();
  const { sortBy, queryInfo } = useWaveFinder();

  const sortInfo = useMemo(() => {
    const waves = market?.waves;

    if(waves) {
      const currentWave = market?.waves[0];

      if(currentWave) {
        const value = parseInt(currentWave[sortBy]);
        let units = queryInfo?.attributes[sortBy].units;
        return (`${toTitleCase(sortBy)}: ${Math.round(value)} ${units}`);
      }
    }
  }, [queryInfo, market, sortBy ]);

  const defaultSortInfo = useMemo(() => {
    const waves = market?.waves;
    if(waves) {
      const currentWave = market?.waves[0];

      if(queryInfo && currentWave) {
        let units = queryInfo?.attributes['elliotticity'].units;
        return (`${toTitleCase('Elliotticity')}: ${Math.round(currentWave?.elliotticity)} ${units}`);
      }
    }
  }, [queryInfo, market]);

  return (
    <Flex className={'chart-footer'} flexDir={'column'} alignItems={'flex-end'} p={'xs'}  pt={'sm'} width={'100%'}>
      {sortInfo
        ?
        <Text fontSize={'sm'} fontWeight={'700'}>{sortInfo}</Text>
        :
        <Text fontSize={'sm'} fontWeight={'700'}>{defaultSortInfo}</Text>
      }
      {sortBy !== 'elliotticity' && sortInfo !== null &&
        <Text fontSize={'xs'} fontWeight={'400'}>({defaultSortInfo})</Text>
      }
    </Flex>
  )
}