//Dependencies
import {
  useState,
  useEffect
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Chakra
import {
  Box,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';

//Providers
import { useView } from '../providers/view';
import { useWaveFinder } from '../providers/wave-finder';

//Components
import {
  SortOrder,
  ViewSelector
} from '../components';


export default function ChartControls() {
  const {setPageNumber} = useWaveFinder();
  const {defaultItemsPerPage, itemsPerPage, setItemsPerPage} = useView();
  const [selectValue, setSelectValue] = useState(itemsPerPage);
  const itemsPerPageChoices = [defaultItemsPerPage, 20, 30];

  const handleItemsPerPageChange = (e) => {
    setSelectValue(e.target.value);
  }

  useEffect(() => {
    setPageNumber(0);
    setItemsPerPage(selectValue);
  }, [selectValue, setPageNumber, setItemsPerPage]);

  return (
    <Stack direction={'column'}>
      <Stack direction={'row'} spacing={false} alignItems={{base: 'flex-start', md: 'flex-end'}} width={'max-content'} mb={'xs'}>
        <SortOrder mr={'sm'} name={'Sort By'} />
        <Stack direction={'row'} align='flex-start' spacing={false} alignItems={'center'}>
          <Text as={'span'} size={'sm'} mr={'xs'}><Text as={'span'} display={{base: 'none', desktop: 'inline-block'}} mr={'xs'}><strong>Items Per</strong></Text><FontAwesomeIcon icon="fa-sharp fa-solid fa-grid-2-plus" /></Text>
          <Box minW={'50px'} minH={6} px={0}>
            <Select size={'standard'} variant={'primary'} value={selectValue}  onChange={handleItemsPerPageChange}>
              {itemsPerPageChoices.map((perPage, index) => ( 
                <option key={index+perPage} value={perPage}>{perPage}</option>
              ))}
            </Select>
          </Box>
        </Stack>
      </Stack>
      <Stack direction={'row'} alignItems={'center'} justifyContent={{base: 'flex-start', md: 'flex-end'}} spacing={false}  display={{base: 'none', sm: 'flex'}}>
        <Text as={'span'} display={{base: 'none', desktop: 'inline-block'}} mr={'xs'}><strong>Grid Size</strong></Text><ViewSelector />
      </Stack>
    </Stack>
  )
}