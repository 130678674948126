// Dependencies
import {
  useEffect,
  useMemo,
} from 'react';
import { useParams } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';

// Chakra
import { Box, Skeleton } from '@chakra-ui/react';

// Providers
import { useView } from '../providers/view';
import { ChartProvider } from '../providers/chart';
import { useMarkets } from '../providers/markets';

// Views
import Single from './single';
import Full from './full';
import Thumbnail from './thumbnail';

// Helpers
import { getMarketBySymbol } from '../helpers/utils';
import { useWaveFinder } from '../providers/wave-finder';


const CurrentView = ({market, view, tags}) => {
  const {fullscreen} = useView();

  const {ref: lazyRef, inView} = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const getView = (view) => {
    switch(view) {
      case 'full':
        return (<Full />);
      case 'thumbnail-small':
        return (<Thumbnail />);
      case 'thumbnail':
        return (<Thumbnail />);
      default:
        return (<Thumbnail />);
    }
  }

  const values = useMemo(() => ({
    market,
    tags,
    hasSearchParams: (view === 'single' ? true : false),
    inView,
  }), [market, tags, view, inView]);

  return (
    <>
      {view !== 'single'
        ?
          <Box
            ref={lazyRef}
            sx={{
              position: 'relative',
              width: '100%',
              height: 'auto',
              backgroundColor: 'chart',
              border: !fullscreen ? '2px solid' : '',
              borderColor: 'bgSecondary',
              borderRadius: 'sm',
              overflow: 'hidden'
            }}
          >
            <ChartProvider values={values}>
              {getView(view)}
            </ChartProvider>
          </Box>
        :
          
          <Box
            ref={lazyRef}
          >
            <ChartProvider values={values}>
              <Single />
            </ChartProvider>
          </Box>
      }
    </>
  )
}

export default function Views({ current = null, renderView}) {
  const {waves} = useWaveFinder();
  const {markets} = useMarkets();
  const {activeGridView, isSingleView, setIsSingleView, activeTags} = useView();
  const {market} = useParams();

  //Get current market for single view
  const activeMarket = useMemo(() => {
    let active = null;

    if(current) {
      active = current;
    } else {
      active = getMarketBySymbol(markets, market);
    }
    active = {
      "json": active,
      "waves": waves?.filter(wave => wave?.market === active?.Id)
    }

    return active;
  }, [current, market, markets, waves]);

  useEffect(() => {
    if(renderView === 'single') {
      setIsSingleView(true);
    } else {
      setIsSingleView(false);
    }
  }, [renderView, setIsSingleView]);
 
  return (
    <>
    {activeMarket &&
      <Box
        className={'chart-container'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: activeGridView.view === 'full' || isSingleView === 'single' ? 'center' : 'normal',
          height: 'auto',
          width: '100%',
          mx: 'auto',
        }}
      >
        <CurrentView market={activeMarket} view={renderView ? renderView : activeGridView.view} tags={activeTags} />
      </Box>
    }
    </>
  );
}