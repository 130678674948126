//Chakra
import {
  Skeleton,
} from '@chakra-ui/react';

//Components
import {
  Chart,
  ChartHeader,
  ChartFooter
} from '../components';

//Providers
import { useView } from '../providers/view';


export default function Full({hasHeader = true, hasFooter = true}) {
  const {isLoadingChartViews} = useView();
   
  const skeletonStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 'sm',
    overflow: 'hidden',
    marginInline: 'auto',
  }
 
  return (
    <>
      <Skeleton
        isLoaded={!isLoadingChartViews}
        w='100%'
        sx={skeletonStyles}
      >
        {hasHeader && <ChartHeader />}
        <Chart />
        {hasFooter && <ChartFooter />}
      </Skeleton>
    </>
  );
}