//Dependencies
import { useLayoutEffect, useState } from 'react';
import { mergeRefs } from "react-merge-refs";
import useResizeObserver from 'use-resize-observer';

//Chakra
import {
  Box,
  Grid,
  GridItem,
  useBreakpointValue,
} from '@chakra-ui/react';

//Providers
import { useMarkets } from '../../providers/markets';
import { ViewProvider } from '../../providers/view';
import { WaveFinderProvider } from '../../providers/wave-finder';

//Assets
import background from '../../assets/background.jpg';

//Helpers
import { ewavesConfig } from '../../config/theme-config';

//Children modules
import Main from './layout-main';
import Header from './layout-header';
import Sidebar from './layout-sidebar';
import styles from './styles';


//Layout creates the underlying structure of the UI and is also the highest level route for using params from the URL
const Layout = (props) => {
  const {markets, current} = useMarkets();
  const [activeSidebar, setActiveSidebar] = useState(true); //Eventually control this via users local storage
  const {ref} = useResizeObserver();
  const mergedCallbackRef = mergeRefs([
    ref,
    (layout) => {
      if(layout) {
        const vh = window.innerHeight * 0.01;
        layout.style.setProperty('--vh', `${vh}px`);


        layout.classList.add('transitioning');
        setTimeout( () => {
          layout.classList.remove('transitioning');
        }, 1000);
      }
    },
  ]); 

  const responsiveWidth = useBreakpointValue({
    base: '15px',
    desktop: '30px'
  });

  useLayoutEffect(() => {
    document.title = `EWAVES Live / ${current ? `${current}` : 'Dashboard'}`;
  }, [current]);

  return (
    <>
      <ViewProvider>
        <WaveFinderProvider>
          {markets?.length &&
            <Grid
              ref={mergedCallbackRef}
              className={'layout'}
              sx={styles}
            >
              <GridItem area={'header'}>
                <Header />
              </GridItem>
              <GridItem area={{base: 'main', desktop: 'sidebar'}} zIndex={100} w={activeSidebar ? `${ewavesConfig.sidebarWidth}px` : responsiveWidth} position='relative' bg='bgPrimary' borderRight='2px' borderRightColor='bgSecondary' transition={'width 0.2s ease-in-out'}>
                <Sidebar active={activeSidebar} setActive={setActiveSidebar} />
              </GridItem>
              {/* <GridItem className={'main-container'} area={'main'} position='relative' ml={{base: '15px', desktop: '0'}} backgroundImage={background} backgroundSize='cover' backgroundPosition={'50% 50%'}> */}
              <GridItem className={'main-container'} area={'main'} position='relative' ml={{base: '15px', desktop: '0'}} backgroundColor={'gray.50'} backgroundSize='cover' backgroundPosition={'50% 50%'}>
                <Box className={'main-backdrop'} />
                <Main />
              </GridItem>
            </Grid>
          }
        </WaveFinderProvider>
      </ViewProvider>
    </>
  );
}

export default Layout;