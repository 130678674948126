// Dependencies
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Chakra
import {
  Box,
  Heading,
  Select,
} from "@chakra-ui/react";

// Helpers
import { toTitleCase } from "../../helpers/utils";

// Providers
import { useSetups } from "../../providers/setups";
import { useBuilder } from "../../providers/builder";


const SetupsSelector = (props) => {
  const {actions} = useBuilder();
  const {setups, currentSetup, setCurrentSetup} = useSetups();
  const isModifiedSetup = false;

  //Loads a setup (built-in or user) when user selects from list of options and then replaces builder state
  const handleSetupSelection = (e) => {
    if(e.target.value === false || e.target.value === 'false') {
      return;
    }

    const selectedSetup = setups[e.target.value];

    const newSetup = {
      index: setups.findIndex(setup => setup.name = selectedSetup.name),
      setup: selectedSetup
    };

    actions.replaceBuilderState(selectedSetup);
    setCurrentSetup(newSetup);
  }

  return (
    <>
    {setups.length &&
          <Box className={'setups-wrapper'} mb={'xs'} w={'100%'}>
          <Heading size={'xs'} pb={'xs'} mb={'0'} color={'gray.600'}>
            <FontAwesomeIcon icon="fa-sharp fa-solid fa-diagram-subtask" /> Setups {isModifiedSetup  && <FontAwesomeIcon icon="fa-solid fa-circle-exclamation" />}
          </Heading>
          <Select value={currentSetup?.index} size={'small'} color={'gray.500'} variant={'lightPrimary'} onChange={handleSetupSelection}>
            <option key={'please select'} value={false}>Select setup</option>
            {setups?.map((setup, index) => (
              <option key={index+setup.name} value={index}>{toTitleCase(setup.name)}</option>
            ))}
          </Select>
        </Box>
    }
    </>
  )
}

export default SetupsSelector;