// Dependencies
import { useEffect, useState, useCallback, useContext, useMemo, createContext } from 'react';
import { onSnapshot, doc, getDoc } from "firebase/firestore";

// Providers
import { convertQueryToBuilderState } from '../builder';

//Helpers
import { db } from '../../helpers/firebase';


const SetupsContext = createContext();

function useSetups() {
  const context = useContext(SetupsContext);
  if (!context) {
    throw new Error(`useSetups must be used within a SetupsProvider`);
  }

  return context;
}

function SetupsProvider(props) {
  const [userSetups, setUserSetups] = useState([]);
  const [builtinSetups, setBuiltinSetups] = useState([]);
  const [currentSetup, setCurrentSetup] = useState();

  // On initial render, get templates data from firebase
  useEffect(() => {
    //Set observer on user snapshot of data
    let unsubscribe = () => console.log('Loading templates...');

    unsubscribe = onSnapshot(doc(db, "site", "config"), (snapshot) => {
      if (snapshot.exists()) {
        const fbTemplates = JSON.parse(snapshot.data().templates);
        console.log('Getting latest templates...', fbTemplates);
        setBuiltinSetups(fbTemplates.map(template => convertQueryToBuilderState(template)));
      } else {
        console.log("No templates found!");
      }
    },
    (error) => {
      console.error(`Attempted to load templates, Firestore returned ${error.code}.`);
    });
    
    //Clean up after render
    return () => unsubscribe();
  }, []);

  // Merge built-in and users setups together into one usable array. ***May map data differently later***
  const setups = useMemo(() => {
    let mergedSetups = [];

    if(userSetups.length) {
      mergedSetups = mergedSetups.concat(userSetups);
    }
    if(builtinSetups.length) {
      mergedSetups = mergedSetups.concat(builtinSetups);
    }
    return mergedSetups;
  }, [userSetups, builtinSetups]);

  const value = useMemo(() => ({
    setups,
    currentSetup, setCurrentSetup,
  }), [setups, currentSetup]);
  
  return <SetupsContext.Provider value={value} {...props} />
}

export {SetupsProvider, useSetups};