//Dependencies
import {
  useMemo
} from "react";

//Chakra
import {
  Box,
  Button,
  VStack,
  IconButton,
  HStack,
} from "@chakra-ui/react";

//Providers
import { useBuilder } from "../../providers/builder";

//Helpers
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Components
import Attribute from "./attribute";


const ContextAttributes = ({id, state}) => {
  const {actions} = useBuilder();
  const context = useMemo(() => state.filter(context => context.id === id).pop(), [state, id]);
  
  const selectedAttributes = useMemo(() => {
    const selected = [...context.attributes].map(attribute => attribute.name);
    return selected;
  }, [context.attributes]);

  return (
    <VStack alignItems={'flex-start'} width={'100%'} spacing={3}>
      {context.attributes.map((attribute, i) => (
        <HStack key={attribute.name+i} bgColor={'gray.200'} position={'relative'} alignItems={'flex-start'} justifyContent={'space-between'} width={'100%'} p={'xs'} pr={'sm'} borderWidth={'0px'} borderColor={'gray.300'} borderRadius={'sm'}>
          <Box position={'absolute'} width={'10px'} height={'2px'} top={'50%'} left={'-10px'} bgColor={'gray.300'} />
          <Attribute contextId={context.id} id={attribute.id} name={attribute.name} values={attribute.values} available={selectedAttributes} />
          <IconButton position={'absolute'} transform={'translate(50%, -50%)'} top={'50%'} right={0} aria-label='Remove Attribute' borderRadius={'full'} size={'xs'} variant={'outline'} colorScheme={'red'} backgroundColor={'white'} icon={<FontAwesomeIcon icon="fa-solid fa-times" />} onClick={() => actions.deleteAttribute(context.id, attribute.id)} />
        </HStack>
      ))}
      <Button variant={'primary'} size={'xs'} color={'gray.500'} borderColor={'gray.300'} _hover={{bgColor:'primary', borderColor: 'primary', color: 'white'}}
        leftIcon={<FontAwesomeIcon icon="fa-solid fa-plus" />}
        onClick={() => actions.addAttribute(context.id)}
      >Add Attribute</Button>
    </VStack>
  )
  
}

export default ContextAttributes;