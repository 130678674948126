// Dependencies
import { useLayoutEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Chakra
import {
  IconButton,
  Stack,
  Heading,
  Box,
  Button,
  useColorMode,
  useBreakpointValue
} from '@chakra-ui/react';

// Providers
import { useView } from '../../providers/view';
import { useWaveFinder } from '../../providers/wave-finder';
import { BuilderProvider } from '../../providers/builder';
import { SetupsProvider } from "../../providers/setups";

// Components
import {
  Panel,
  Markets,
  SetupsBuilder
} from '../../components';


export default function Sidebar(props) {
  const {active, setActive} = props;
  const {queryInfo} = useWaveFinder();
  const {colorMode} = useColorMode();
  const isScreenDesktop = useBreakpointValue({
    base: false,
    desktop: true
  },
  {
    ssr: false
  });

  // Set status based on first load of window size
  useLayoutEffect(() => {
    setActive(isScreenDesktop);
  }, []);

  // Open on close sidebar based on current window size
  useLayoutEffect(() => {
    const handleResize = () => {
      if(isScreenDesktop) {
        setActive(true);
      } else {
        setActive(false);
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [setActive, isScreenDesktop]);


  return (
    <>
      <IconButton sx={{
          position: 'absolute',
          top: {base: '50%', desktop: 4},
          right: '0',
          transform: {base: active ? 'translate(70%, -50%)' : 'translate(50%, -50%)', desktop: 'translate(50%)'},
          opacity: active ? '0.60' : '1.0',
          borderRadius: 'sm',
          zIndex: '1000',
        }}
        variant='solid'
        aria-label={'Toggle Sidebar'}
        title={'Toggle Sidebar'}
        background={colorMode === 'light' ? 'gray.200' : 'gray.700'}
        icon={active ? <FontAwesomeIcon icon='fa-solid fa-sidebar' /> : <FontAwesomeIcon icon='fa-thin fa-sidebar' />}
        onClick={ () => setActive(!active) }
        transition={'all 0.2s ease-in-out'}
      />
      <Panel
        className={'sidebar'}
        p={{base: 'sm', sm: 'xs', }}
        justifyContent={'flex-start'}
        alignItems={'center'}
        overflowX={'hidden'}
        overflowY={'show'}
        opacity={ active ? '1' : '0'}
        transition={'all 0.3s ease-in-out 0.1s'}
        sx={{scrollbarGutter: "stable"}}
      >
        <Stack w={'100%'} p={'xs'} pb={0} spacing={6} direction='column' opacity={active ? '1' : '0'} transition={'all 0.1s ease-in-out'}>
          <Markets />
          {queryInfo && 
            <BuilderProvider>
              <SetupsProvider>
                <SetupsBuilder />
              </SetupsProvider>
            </BuilderProvider>
          }
        </Stack>
      </Panel>
    </>
  );
}