//Dependencies
import {
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';

//Chakra
import {
  Grid,
  GridItem,
  Button,
  Box,
  useBreakpointValue
} from '@chakra-ui/react';

//Providers
import { useMarkets } from '../providers/markets';
import { useView } from '../providers/view';
import { useWaveFinder } from '../providers/wave-finder';

//Views
import Views from '../views';

//Helpers
import { ewavesConfig } from '../config/theme-config';
import { getMarketsByWaves } from '../helpers/utils';


//Customized settings for ReactPaginate Component
const Pagination = ({pageCount, currPage, handlePageClick}) => {
  const pageRangeDisplayed = useBreakpointValue({
    base: 0,
    desktop: 5
  },
  {
    ssr: false
  });
  const marginRangeDisplayed = useBreakpointValue({
    base: 0,
    sm: 1,
    desktop: 2
  },
  {
    ssr: false
  });

  return (
    <ReactPaginate
      className='pagination'
      pageClassName='pagination__page'
      previousClassName='pagination__page-previous'
      nextClassName='pagination__page-next'
      breakLabel="..."
      nextLabel={
        <Button
          size="sm"
          rightIcon={<FontAwesomeIcon icon="fa-light fa-chevron-right" />}
          variant={'ghost'}
          className={'pagination__button'}
        >
          Next
        </Button>
      }
      previousLabel={
        <Button
          size="sm"
          leftIcon={<FontAwesomeIcon icon="fa-light fa-chevron-left" />}
          variant={'ghost'}
          className={'pagination__button'}
        >
          Previous
        </Button>
      }
      onPageChange={handlePageClick}
      pageRangeDisplayed={pageRangeDisplayed}
      marginPagesDisplayed={marginRangeDisplayed}
      forcePage={currPage}
      pageCount={pageCount}
    />
  )
}

//Controls paginated results display
export default function ChartPagination() {
  const {markets} = useMarkets();
  const {containerRef, activeGridView, itemsPerPage} = useView();
  const {waves, maxWaves, pageNumber, setPageNumber} = useWaveFinder();

  const prevMaxWaves = useRef(maxWaves?.qty);

  //Get markets from list of waves. Wave filtering is handled by WaveFinderProvider for us 
  const items = useMemo(() => {
    let items = null;

    if(markets.length && waves.length) {
      items = getMarketsByWaves(markets, waves);
    }
    return items;
  }, [markets, waves]);

  //Calculate number of pages for current Wave Finder query result
  const pageCount = useMemo(() => Math.ceil(maxWaves?.qty / itemsPerPage), [maxWaves, itemsPerPage]);

  //On click, update Wave Finder page number to retrieve next items
  const handlePageClick = (event) => {
    setPageNumber(event.selected);
    containerRef.current.scrollIntoView();
  };

  // Make sure to reset page number if wavefinder is toggled on/off
  useEffect(() => {
    if(prevMaxWaves.current !== maxWaves?.qty) {
      console.log('Resetting page number to 0...');
      setPageNumber(0);
      prevMaxWaves.current = maxWaves.qty;
    }
  }, [maxWaves, setPageNumber]);

  return (
    <>
      <Grid
        className={'grid'}
        templateColumns={activeGridView.size}
        gap={{base: `${ewavesConfig.chartGridGap / 2}px`, desktop: `${ewavesConfig.chartGridGap}px`}}
        rowGap={{base: 2, lg: 5, '2xl': 7}}
        justifyItems={'center'}
      >
        {items?.length > 0 && items.map((item, index) => (
          <GridItem key={index+'-'+item.Symbol} className={'grid__item'} position={'relative'} width={'100%'} scrollSnapAlign={'center'} overflow={'hidden'}>
            { item &&
              <Views current={item} />
            }
          </GridItem>
        )) }
      </Grid>
      {pageCount > 1 && (
        <Box mt='lg' mb='lg'>
          <Pagination pageCount={pageCount} currPage={pageNumber} handlePageClick={handlePageClick} />
        </Box>
      )}
    </>
  )
}