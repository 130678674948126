//Dependencies
import {
  useState,
  useEffect
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Chakra
import {
  Box,
  IconButton,
  Text,
  HStack,
  Select
} from "@chakra-ui/react";

//Providers
import { useWaveFinder } from "../providers/wave-finder";

//Helpers
import { toTitleCase } from "../helpers/utils";


const SortAscendingButton = (props) => {
  const {sortAsc, setSortAsc, setPageNumber} = useWaveFinder();
  const switchIcon = sortAsc ? <FontAwesomeIcon icon="fa-solid fa-bars-sort" flip="vertical" /> : <FontAwesomeIcon icon="fa-solid fa-bars-sort" />
  const toggleSortAsc = () => {
    setPageNumber(0);
    if(sortAsc) {
      setSortAsc(false);

    } else {
      setSortAsc(true);
    }
  }

  return (
    <IconButton
      size={'sm'}
      colorScheme={'gray'}
      variant={'primary'}
      aria-label={`Sorted by ${sortAsc ? 'Ascending' : 'Descending'}`}
      title={`Sorted by ${sortAsc ? 'Ascending' : 'Descending'}`}
      onClick={toggleSortAsc}
      icon={switchIcon}
      {...props}
    />
  )
}

export default function SortOrder({name, ...props}) {
  const {queryInfo, sortBy, setSortBy, setPageNumber} = useWaveFinder();
  const [selectValue, setSelectValue] = useState(sortBy);

  const handleSortOrder = (e) => {
    setSelectValue(e.target.value);
  }

  useEffect(() => {
    setPageNumber(0);
    setSortBy(selectValue);
  }, [selectValue, setPageNumber, setSortBy]);
  
  return (
    <HStack {...props} align='flex-start' spacing={'0'} alignItems={'center'}>
      <Text as={'span'} size={'sm'} mr={'xs'}><Text as={'span'} display={{base: 'none', desktop: 'inline-block'}} mr={'xs'}><strong>{name}</strong></Text><SortAscendingButton /></Text>
        <Box w={'auto'} minH={6} px={0}>
          <Select value={selectValue} size={'standard'} variant={'primary'} onChange={handleSortOrder}>
            {queryInfo?.sortBy.map((rank, index) => ( 
              <option key={index+rank} value={rank}>{toTitleCase(rank)}</option>
            ))}
          </Select>
        </Box>
    </HStack>
  )
}