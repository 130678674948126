//Dependencies
import { useState, useEffect, useContext, useMemo, useRef, createContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import isEqual from 'lodash.isequal';

//Providers
import { useWaveFinder } from '../providers/wave-finder';
import { useUser } from '../providers/user';

//Helpers
import { load } from '../helpers/bchart';
import { createMarketURLBinaryTokenized } from '../helpers/utils';
import { useView } from './view';


const ChartContext = createContext();

function useChart() {
  const context = useContext(ChartContext);
  if (!context) {
    throw new Error(`useChart must be used within a ChartProvider`);
  }

  return context;
}

function ChartProvider({values: {market, tags, hasSearchParams, inView}, ...props}) {
  const {user} = useUser();
  const {wfActive, isLoadingWaves} = useWaveFinder();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamZoom = useMemo(() => parseInt(searchParams.get('z')), [searchParams]); //if z doesn't exist then NaN is returned
  const [zoom, setZoom] = useState(null); //used to sync zoom between chart components (NOTE: Not persistent navigating from Single -> Charts - Requires refractor)
  const [width, setWidth] = useState(null); //used to adjust single and full views
  const [chartBlob, setChartBlob] = useState(null);

  const prevPath = useRef(null);

  //Necessary for creating bchart
  const [ bchart, setBchart ] = useState(null);

  const value = useMemo(() => ({
    zoom, setZoom,
    width, setWidth,
    chartBlob, setChartBlob,
    market,
    tags,
    bchart,
    hasSearchParams
  }), [zoom, width, market, tags, hasSearchParams, bchart, chartBlob]);

  //On chart provider's first render, we'll setup what zoom should be
  useEffect(() => {
    if(bchart) {
      const numOfZooms = bchart.zooms.length - 1;

      //Loading single view either from internal link or directly via URL
      if(hasSearchParams) {
        if(isNaN(parseInt(searchParamZoom))) { 
          setZoom(bchart.defaultZoom);
        } else if(searchParamZoom <= 0) { // Can't be lower than zoom level 0
          setZoom(0);
        } else if(searchParamZoom >= numOfZooms) { // Can't be higher than zoom limit
          setZoom(numOfZooms);
        } else {
          setZoom(parseInt(searchParamZoom));
        }
        return;
      }
      
      if(wfActive) {
        setZoom(market.waves[0].zoom);
      } else {
        setZoom(bchart.defaultZoom);
      }

      if(isLoadingWaves) {
        setZoom(null);
      }
    }
  }, [wfActive, market, bchart, hasSearchParams, searchParamZoom, isLoadingWaves]);

  // Apply zoom updates based on changes to UI
  useEffect(() => {
    if(hasSearchParams && (zoom || zoom === 0)) {//Loading single view either from internal link or directly via URL
      setSearchParams({z: zoom}, {replace: true}); //sets zoom in url to match zoom in chart when changed via zoom buttons
    }
  }, [hasSearchParams, setSearchParams, zoom]);

  // Loads the binary chart data object into chart provider when it's available (inView)
  useEffect(() => {
    const getBinaryChartAsync = async (marketToken, userPackage, cleanup) => {
      const path = createMarketURLBinaryTokenized(marketToken, userPackage);
      try {
        if(path) {
          const object = await load(path, cleanup);
          setBchart(object);
        }
      } catch (error) {
        console.error('Error loading binary path', error);
      }
    }

    const cleanup = new AbortController();

    if(market?.json?.Token && user?.package && inView) {
      getBinaryChartAsync(market.json.Token, user.package, cleanup);
    }

    return () => {
      cleanup.abort();
    }
  }, [market?.json?.Token, user?.package, inView]);

  return <ChartContext.Provider value={value} {...props} />
}

export {ChartProvider, useChart};