//Chakra
import {
  Skeleton,
} from '@chakra-ui/react';

//Components
import {
  Chart,
  ChartHeader,
  ChartFooter
 } from "../components";

 //Providers
import { useView } from '../providers/view';


export default function Thumbnail() {
  const {isLoadingChartViews} = useView();

  return (
    <>
      <Skeleton isLoaded={!isLoadingChartViews}>
        <ChartHeader />
        <Chart />
        <ChartFooter />
      </Skeleton>
    </>
  );
}