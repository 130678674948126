// Dependencies
import {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
  createContext,
  useReducer
} from 'react';
import uniqid from 'uniqid';

// Providers
import { useWaveFinder } from '../wave-finder';

// Helpers
import { setFBUser } from "../../helpers/firebase";
import { serverTimestamp } from "firebase/firestore";
import { useAuthentication } from "../authentication";


const BuilderContext = createContext();

// Default values for when query is reset
const prefixCont = 'c-';
const prefixAttr = 'a-';

// Default state for if builder encounters no saved user ui state (query from Firebase)
const defaultBuilderState = {
  name: null,
  setup: [
    {
      attributes: [],
      name: "wave",
      id: "c-default-wave",
    }
  ]
};

// Query Reducer setups all functionality for controlling UI state object
// !!! MAKE SURE ALL RETURN VALUES ARE NOW {NAME, SETUP} !!!
function builderStateReducer(state, action) {
  const {name, setup} = state;
  switch(action.type) {
    case 'ADD_CONTEXT': {
      const newSetup = [...setup, {
        id: uniqid(prefixCont), // We'll add the context name to this Id once it's selected later
        name: action.value,
        attributes: [{id: uniqid(prefixAttr), name: null, values: null}]
      }];

      return ({
        name,
        setup: newSetup
      });
    }
    case 'UPDATE_CONTEXT': {
      const newSetup = setup.map(context => {
        if(context.id === action.id) {
          context.id = context.id + '-' + action.value.toLowerCase().replace(/[^a-zA-Z0-9\s]/g, "").replace(/\s/g, "_"); // lowercase and replace spaces
          context.name = action.value;
          return context;
        } else {
          return context;
        }
      });

      return ({
        name,
        setup: newSetup
      });
    }
    case 'DELETE_CONTEXT': {
      const newSetup = setup.filter(context => context.id !== action.id);

      return ({
        name,
        setup: newSetup
      });
    }
    case 'CLEAR_STATE': {
      return ({
        name,
        setup: []
      });
    }
    case 'REPLACE_STATE': {
      return action.value;
    }
    case 'ADD_ATTRIBUTE': {
      //Get the index of the context we're going to update
      const currContextIndex = setup.findIndex(context => {
        return context.id === action.id
      });
      //Add a new default attribute to the attributes array for the context
      const updatedContextWithNewAttrArray = {...setup[currContextIndex], attributes: [...setup[currContextIndex].attributes, {id: uniqid(prefixAttr), name: null, values: null}]};

      //Return a new array with the updated context
      return ({
        name,
        setup: [
          ...setup.slice(0, currContextIndex),
          updatedContextWithNewAttrArray,
          ...setup.slice(currContextIndex + 1)
        ]
      });
    }
    case 'UPDATE_ATTRIBUTE': {
      const newSetup = [...setup].map(context => {
        if(context.id === action.contextId) {
          //If matching context then update attributes with new attributes array containing updated value
          context.attributes = [...context.attributes].map(attribute => {
            if(attribute.id === action.id) {
              //If matching attribute then update name and return
              attribute.name = action.name ? action.name : attribute.name;
              attribute.values = action.values ? action.values : attribute.values;
              return attribute;
            }
            return attribute;
          });
          return context;
        }
        return context;
      });

      return ({
        name,
        setup: newSetup
      });
    }
    case 'DELETE_ATTRIBUTE': {
      //Get the index of the context we're going to update
      const currContextIndex = setup.findIndex(context => context.id === action.contextId);
      //Find attributes for correct context using index and copy current attributes minus the deleted one
      const updatedContextAttributes = {...setup[currContextIndex], attributes: [...setup[currContextIndex].attributes].filter(attribute => attribute.id !== action.id)}
      //Return a new array with the updated context
      return ({
        name,
        setup: [
          ...setup.slice(0, currContextIndex),
          updatedContextAttributes,
          ...setup.slice(currContextIndex + 1)
        ]
      });
    }
    default: {
      throw new Error(`Unsupported action type: ${action.type}`);
    }
  }
}

function BuilderProvider(props) {
  const {authentication} = useAuthentication();
  const {queryInfo, setWfQuery, setBuilderStateReady} = useWaveFinder();
  
  // Grab attribute info from query info and format it to use for input generation
  const fields = useMemo(() => {
    if(queryInfo) {
      return Object.entries(queryInfo.attributes).map(([key, value]) => {
        if(value.type === 'list') {
          return {name: key, type: value.type, options: value.values}
        }
        if(value.type === 'range') {
          return {name: key, type: value.type, units: value.units, min: value.min, max: value.max}
        }
        return console.error('Unknown attribute type specified in query information.');
      });
    }
    return null;
  }, [queryInfo]);

  // Grab context wave labels for use in builder
  const contextWaves = useMemo(() => queryInfo ? queryInfo.waves : null, [queryInfo]);

  // On initial render will verify that state array has only valid values
  const initBuilder = useCallback(({name, setup}) => {
    console.log('Retrieved setup from user...', setup);
    if(setup && fields && contextWaves) {
      // Setup reference values to check against
      const waveRef = contextWaves;
      const fieldsRef = fields;
      const valuesListRef = fields.filter(field => field.type === 'list');
      
      // Check all context waves to ensure their of the correct type
      let checkedState = setup.filter(context => waveRef.some(ref => ref === context.name));
      
      // Check all attributes in validated context wave to make sure they're a correct type
      checkedState = [...checkedState].map(context => ({
        ...context,
        attributes: context.attributes.filter(attr => fieldsRef.some(ref => ref.name === attr.name)),
      }));
  
      // Check all the values inside validated attrs to make sure they're allowed as well
      checkedState = [...checkedState].map(context => {
        context.attributes = context.attributes.map(attribute => {
          if(Array.isArray(attribute.values)) { // If array of values, filter invalid values out and return the remaining
            attribute.values = attribute.values.filter(value => {
              const currListRef = valuesListRef.filter(ref => attribute.name === ref.name).pop(); // Get the correct ref values for list type
              return currListRef.options.some(ref => ref === value);
            });
          }
          return attribute;
        });
        return context;
      });
      console.log('Verified state...', {name, setup});
      return ({name, setup: checkedState});
    }
    console.warn('User query not validated because of error with reference and/or retrieving user query.');
    return defaultBuilderState;
  }, [fields, contextWaves]);

  // Reducer controls all the state actions for updating the query builder itself
  const [builderState, dispatch] = useReducer(builderStateReducer, defaultBuilderState, initBuilder); // Reducer will turn query into usable builder state

  // Saves builder setup to Firebase user
  const saveBuilderStateToFBUser = useCallback(async () => {
    try {
      await setFBUser(authentication.uid, { querySetup: builderState, queryTimestamp: serverTimestamp() });
    } catch (e) {
      return console.error(e);
    }
  }, [builderState, authentication?.uid]);

  // When builder state is changed, we need to update the Wave Finder query object and set flag to allow Wave Finder to find waves
  useEffect(() => {
    if(builderState && builderState.setup.length) {
      const newQuery = convertBuilderStateToQuery(builderState.setup);
      setWfQuery(newQuery);
      setBuilderStateReady(true);
    }
  }, [builderState, setWfQuery, setBuilderStateReady]);

  const value = useMemo(() => ({
    builderState,
    dispatch,
    fields,
    contextWaves,
    saveBuilderStateToFBUser
  }), [builderState, fields, contextWaves, saveBuilderStateToFBUser]);

  return <BuilderContext.Provider value={value} {...props} />
}

function useBuilder() {
  const context = useContext(BuilderContext);
  if (!context) {
    throw new Error(`useQuery must be used within a QueryProvider`);
  }
  const {dispatch} = context;

  const actions = useMemo(() => {
    const addContext = (value = null) => dispatch({
      type: 'ADD_CONTEXT',
      value: value
    });
  
    const updateContext = (id, value) => dispatch({
      type: 'UPDATE_CONTEXT',
      id: id,
      value: value
    });
  
    const deleteContext = (id) => dispatch({
      type: 'DELETE_CONTEXT',
      id: id
    });
  
    const addAttribute = (id) => dispatch({
      type: 'ADD_ATTRIBUTE',
      id: id
    });
  
    const updateAttribute = (contextId, id, name = null, values = null) => dispatch({
      type: 'UPDATE_ATTRIBUTE',
      contextId: contextId,
      id: id,
      name: name,
      values: values
    });
  
    const deleteAttribute = (contextId, id) => dispatch({
      type: 'DELETE_ATTRIBUTE',
      contextId: contextId,
      id: id
    });
  
    const clearBuilderState = () => dispatch({
      type: 'CLEAR_STATE'
    });
    
    const replaceBuilderState = (value) => dispatch({
      type: 'REPLACE_STATE',
      value: value
    });

    return ({
      addContext,
      updateContext,
      deleteContext,
      addAttribute,
      updateAttribute,
      deleteAttribute,
      replaceBuilderState,
      clearBuilderState,
    })
  }, [dispatch]);

  return {
    actions,
    ...context,
  }
}

/**
 * Helper function takes wave query objects and converts them into state arrays for the user interface
 * @param {*} wfQuery Query object that is used with Wave Finder
 * @returns UI State object ready for Query Builder
 */
const convertQueryToBuilderState = (wfQuery) => {
  const {name, query} = wfQuery;
  const newSetup = Object.entries(query).map(([cKey, cValue]) => {
    return ({
      "name": cKey,
      "id": uniqid(prefixCont, `-${cKey}`),
      "attributes": Object.entries(cValue).map(([aKey, aValue]) => {
        return ({
          "id": uniqid(prefixAttr, `-${aKey}`),
          "name": aKey,
          "values": aValue,
        })
      }),
    });
  });

  return ({
    name: name,
    setup: newSetup
  });
}

/**
 * Helper function takes state arrays of objects and converts them into a multidimensional object that the Wave Finder can use
 * @param {*} builderState 
 * @returns Multidimensional array ready to pass to Wave Finder
 */
const convertBuilderStateToQuery = (builderState) => {
  const isNotEmpty = (obj) => {
    return Array.isArray(obj) ? obj.length > 0 : 
          (typeof obj === 'object' && Object.keys(obj).length > 0);
  }
  const newQuery = {};

  if(!builderState) return ({}); 

  builderState.filter(context => context.name).forEach(context => {
    if(context.name) {
      newQuery[context.name] = {};
      if(context.attributes) {
        context.attributes.forEach(attribute => {
          if(attribute.name) {
            if(attribute.values && isNotEmpty(attribute.values)) {
              newQuery[context.name][attribute.name] = attribute.values;
            }
          }
        });
      }
    }
  });
  
  return newQuery;
}

export {
  BuilderProvider,
  useBuilder,
  convertQueryToBuilderState,
  convertBuilderStateToQuery
};